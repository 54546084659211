import {useQuery} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {Album} from '@app/web-player/albums/album';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';

interface Response extends BackendResponse {
  albums: Album[];
}

export function useLandingPageNewRelease() {
  return useQuery({
    queryKey: ['landing', 'new-release'],
    queryFn: () => fetchAlbums(),
    initialData: () => {
      const data = getBootstrapData().loaders?.landingPage;
      if (data?.newRelease) {
        return {albums: data.newRelease};
      }
      return undefined;
    },
  });
}

function fetchAlbums() {
  return apiClient
    .get<Response>('landing/new-release')
    .then(response => response.data);
}
